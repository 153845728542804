import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
//import store from "@/store";

/**
 *
 * @param {string} type
 * @returns form object
 */
export async function loadForms(type) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "query { form (type: " +
    type +
    ") { title showFieldsetsAsPages fieldsets { title fields { label name value type inputType checkedValue uncheckedValue options validation { type additional } } } } }";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadForms", "data-resolver forms", e)();
    throw e;
  });
  return retval.data.data.form;
}

export async function sendForm(input) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    //Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    "mutation{ submitForm(input: { " +
    input +
    " } ){ has_errors thank_you_message fields { name value errors { message } } } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("createGuestCart", "data-resolver carts", e)();
    console.log(e);
    throw e;
  });
  if (retval.data.data.submitForm != null) {
    return retval.data.data.submitForm;
  } else {
    return false;
  }
}
