import { Logger } from "@storefront/core/lib/logger";
import { loadBlogList, loadBlog } from "@storefront/core/data-resolver/blog";

const state = () => ({
  current: null, // shown product
  list: [],
  currentPageNr: 1,
  currentPage: [],
  totalPages: 1,
  totalBlogs: 0,
  blogsPerPage: 9,
});

const actions = {
  /**
   *
   * load blogs
   */
  async load({ commit, getters }) {
    const currentPage = 1;
    const page = await loadBlogList(
      currentPage,
      getters["getBlogsPerPage"]
    ).catch((e) => {
      Logger.error("load", "blog store actions", e)();
      throw e;
    });
    if (page != null) {
      if (page.items != null) {
        commit("setTotalPages", page.total_pages);
        commit("setTotalBlogs", page.total_count);
        const obj = { page: currentPage, items: page.items };
        commit("setListPage", obj);
        commit("setCurrentPage", page.items);
      }
    }
  },
  /**
   * go to page on blog overview
   * @param {json} pageNr
   */
  async goToPage({ commit, getters }, { pageNr }) {
    if (pageNr > 0) {
      if (getters["getTotalPages"] >= pageNr) {
        const found = getters["getList"].find((v) => {
          if (v.page == pageNr) {
            return true;
          }
        });
        if (found != null) {
          commit("setCurrentPage", found.items);
        } else {
          const page = await loadBlogList(
            pageNr,
            getters["getBlogsPerPage"]
          ).catch((e) => {
            Logger.error("load", "blog store actions", e)();
            throw e;
          });
          if (page != null) {
            if (page.items != null) {
              const obj = { page: pageNr, items: page.items };
              commit("setListPage", obj);
              commit("setCurrentPage", page.items);
              commit("setCurrentPageNr", pageNr);
            }
          }
        }
      }
    }
  },
  /**
   * load Blog by Identifier
   * @param {json} identifier
   */
  async loadBlog({ commit }, { identifier }) {
    const page = await loadBlog(identifier).catch((e) => {
      Logger.error("load", "blog store actions", e)();
      throw e;
    });
    if (page != null) {
      commit("setCurrent", page);
    }
  },
};

const mutations = {
  /**
   * set list page
   * @param {object} state
   * @param {object} payload
   */
  setListPage(state, payload) {
    state.list.push(payload);
  },
  /**
   * set total pages
   * @param {object} state
   * @param {integer} payload
   */
  setTotalPages(state, payload) {
    state.totalPages = payload;
  },
  /**
   * set blogs per page
   * @param {object} state
   * @param {integer} payload
   */

  setBlogsPerPage(state, payload) {
    state.blogsPerPage = payload;
  },
  /**
   * set set total blogs
   * @param {object} state
   * @param {integer} payload
   */

  setTotalBlogs(state, payload) {
    state.totalBlogs = payload;
  },
  /**
   * set current page
   * @param {object} state
   * @param {array} payload
   */
  setCurrentPage(state, payload) {
    state.currentPage = payload;
  },
  /**
   *
   * @param {object} state
   * @param {integer} payload
   */
  setCurrentPageNr(state, payload) {
    if (payload > state.totalPages) {
      if (payload >= 1) {
        state.currentPageNr = payload;
      }
    }
  },
  /**
   *
   * @param {object} state
   * @param {object} payload
   */
  setCurrent(state, payload) {
    state.current = payload;
  },
};

const getters = {
  getBlogsPerPage: (state) => state.blogsPerPage,
  getTotalPages: (state) => state.totalPages,
  getTotalBlogs: (state) => state.totalBlogs,
  getList: (state) => state.list,
  getCurrentPage: (state) => state.currentPage,
  getBreadcrumbsCurrent: (state) => state.current,
  getCurrentPageNr: (state) => state.currentPageNr,
  getBlog: (state) => state.current,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
