import { loadCategoryMenu } from "@storefront/core/data-resolver/categories";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";

const state = () => ({
  menuItems: [],
  menuState: [],
  navBar: false,
});

const actions = {
  toggleNavbar({ state, commit }) {
    if (state.navBar == true) {
      commit("setNavBar", false);
    } else {
      commit("setNavBar", true);
    }
  },
  async loadMenu({ commit }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const root_category = storelang["root_category"];

    const category = await loadCategoryMenu(root_category).catch((e) => {
      Logger.error("loadMenu", "menu.ts", e)();
      throw e;
    });


    const menu = [];
    category.children.forEach((child) => {
      if (child.include_in_menu == 1) {
        const item = {
          id: child.id,
          itemName: child.name,
          linkTo: "/" + child.url_path,
          hasDropMenu: false,
          dropMenu: [],
        };
        child.linkTo = "/" + child.url_path;

        child.children.sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        });
        let iCount = 1;
        let subItems = [];
        child.children.forEach((sChild) => {
          if (sChild.include_in_menu == 1) {
            const sItem = {
              id: sChild.id,
              name: sChild.name,
              linkTo: "/" + sChild.url_path,
            };
            subItems.push(sItem);
            if (iCount == 11) {
              item.dropMenu.push(subItems);
              subItems = [];
              iCount = 1;
            }
          }
        });
        if (subItems.length > 0 && iCount < 11) {
          item.dropMenu.push(subItems);
        }

        if (item.dropMenu.length > 0) {
          item.hasDropMenu = true;
          item.dropMenuState = "collapsed";
        }
        menu.push(item);
        commit("setMenuState", { id: item.id, state: false });
      }
    });

    commit("setMenuItems", menu);
  },
};

const mutations = {
  setMenuItems(state, payload) {
    state.menuItems = payload;
  },
  setMenuState(state, payload) {
    state.menuState.push(payload);
  },
  setNavBar(state, payload) {
    state.navBar = payload;
  },
  updateMenuState(state, payload) {
    state.menuState.find((v) => {
      if (v.id == payload.id) {
        v.state = payload.state;
      }
    });
  },
};

const getters = {
  getMenuItems: (state) => state.menuItems,
  getMenuItemState: (state) => (id) =>
    state.menuState.find((v) => {
      if (v.id == id) {
        return true;
      }
    }),

  getNavbar: (state) => {
    return state.navBar;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
