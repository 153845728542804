import {
  createGuestCart,
  loadCart,
  addSimpleProduct,
  addProduct,
  addConfigurableProduct,
  addBundleProduct,
  setShippingAddressById,
  setShippingAddress,
  setEmailToCart,
  setBillingAddressById,
  setBillingAddress,
  setShippingMethodsOnCart,
  //setPaymentMethodOnCart,
  getFreeShipping,
  updateCartItem,
  removeCartItem,
  addCouponCode,
  removeCouponCode,
  // getPaymentmethods,
  setPaymentMethod,
  getPickupLocations,
  setPickupLocation,
  getPaymentmethods
} from "@storefront/core/data-resolver/cart";
import config from "@config";
//import { sumBy } from "lodash";
import store from "@/store";

const state = () => ({
  cartIsLoaded: false,
  cartItems: [],
  cartQuantity: 0,
  cartServerToken: null,
  cartShippingAddress: null,
  cartBillingAddress: null,
  cartShippingMethods: [],
  cartPaymentMethods: [],
  cartShippingMethod: null,
  cartPaymentMethod: null,
  cartPaymentMethodOptions: null,
  freeShippingAmount: 0,
  freeShipping: null,
  cartShippingAddressId: null,
  cartBillingAddressId: null,
  cartEmail: null,
  cartPrices: {},
  defaultShipping: {},
  pickupLocations: [],
  comment: "",
  Fake: null,
  delivery_time: {},
  applied_coupons: [],
  storeValidation: {},
  accountPassword: "",
  createAccount: false
});

const actions = {
  /**
   * Load Shopping cart
   *
   */
  async loadCart({ getters, commit, dispatch }) {
    if (getters["getCartIsLoaded"] == false) {
      if (getters["getCartServerToken"] == null) {
        const serverToken = localStorage.getItem("cartServerToken");
        if (serverToken !== null) {
          commit("setServerToken", serverToken);
        }
      }
    }
    if (getters["getCartServerToken"] != null) {
      const cart = await loadCart();
      dispatch('getFreeShipping');
      if (cart == false) {
        commit("setCartItems", []);
        commit("setCartIsLoaded", false);
        commit("setServerToken", null);
        commit("setShippingAddress", null);
        commit("setShippingAddressId", null);
        commit("setBillingAddress", null);
        commit("setBillingAddressId", null);
        commit("setShippingMethods", []);
        commit("setShippingMethod", null);
        commit("setPaymentMethods", []);
        commit("setPaymentMethod", null);
        commit("setEmail", null);
        commit("setCartPrices", {});
        commit("setDefaultShipping", {});
        commit("setAppliedCouponCodes", []);
      } else {
        cart.items.forEach((element, index) => {
          if (element == null) {
            cart.items.splice(index, 1);
          }
        });
        commit("setCartItems", cart.items);
        commit("setCartIsLoaded", true);
        commit("setBillingAddress", cart.billing_address);
        commit("setEmail", cart.email);
        commit("setCartPrices", cart.prices);
        commit("setDefaultShipping", cart.default_shipping_method);
        commit("setAppliedCouponCodes", cart.applied_coupons);
        if (cart.available_payment_methods && (getters['cart/getPaymentMethods'] == [] || getters['cart/getPaymentMethods'] == null)) {
          var methods = await getPaymentmethods();
          commit('setPaymentMethods', methods);
        }

        if (typeof cart.selected_payment_method != "undefined") {
          var localStorageData = JSON.parse(localStorage.getItem('checkoutPayment'));
          if (cart.selected_payment_method.code != "") {
            commit("setPaymentMethod", cart.selected_payment_method.code);
          } else if (localStorageData) {
            commit("setPaymentMethod", localStorageData.billing);
          }
          else {
            commit("setPaymentMethod", null);
          }
        }
        if (typeof cart.shipping_addresses != "undefined") {
          if (cart.shipping_addresses.length > 0) {
            const address = {
              id: null,
              firstname: "",
              lastname: "",
              street: [],
              postcode: "",
              city: "",
              country: {},
              telephone: "",
              company: "",
            };
            address.id = cart.shipping_addresses[0].id;
            address.firstname = cart.shipping_addresses[0].firstname;
            address.lastname = cart.shipping_addresses[0].lastname;
            address.street = cart.shipping_addresses[0].street;
            address.postcode = cart.shipping_addresses[0].postcode;
            address.city = cart.shipping_addresses[0].city;
            address.country = cart.shipping_addresses[0].country;
            address.telephone = cart.shipping_addresses[0].telephone;
            address.company = cart.shipping_addresses[0].company;
            address.vat_id = cart.shipping_addresses[0].vat_id;
            commit("setShippingAddress", address);
            commit(
              "setShippingMethods",
              cart.shipping_addresses[0].available_shipping_methods
            );
            commit(
              "setShippingMethod",
              cart.shipping_addresses[0].selected_shipping_method
            );
          } else {
            commit("setShippingAddress", null);
            commit("setShippingMethods", []);
            commit("setShippingMethod", null);
          }
        } else {
          commit("setShippingAddress", null);
          commit("setShippingMethods", []);
          commit("setShippingMethod", null);
        }
        commit("setCartDeliveryTime", cart.delivery_time);
      }
    }
  },
  /**
   *Unload shopping cart
   *
   */
  unLoad({ commit }) {
    commit("setCartItems", []);
    commit("setCartIsLoaded", false);
    commit("setServerToken", null);
    commit("setShippingAddress", null);
    commit("setShippingAddressId", null);
    commit("setBillingAddress", null);
    commit("setBillingAddressId", null);
    commit("setShippingMethods", []);
    commit("setShippingMethod", null);
    commit("setPaymentMethods", []);
    commit("setPaymentMethod", null);
    commit("setEmail", null);
    commit("setCartPrices", {});
    commit("setDefaultShipping", {});
    commit("setCartDeliveryTime", {});
    commit("setAppliedCouponCodes", []);
  },
  /**
   * Get freeshipping amount from magento
   *
   */
  async getFreeShipping({ commit }) {
    const freeS = await getFreeShipping();
    commit("setFreeShipping", freeS);
    if (freeS != null) {
      commit("setFreeShippingAmount", freeS.default_amount);
    }
  },
  /**
   *
   *
   * @param {object} integer id  integer quantity
   * @returns true or false
   */
  async updateCartItem({ commit }, { id, quantity }) {
    commit("setFake", null);
    const retval = await updateCartItem(id, quantity);
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * Remove item from shopping cart
   *
   * @param {object} integer id
   * @returns
   */
  async removeCartItem({ commit }, { id }) {
    commit("setFake", null);
    const retval = await removeCartItem(id);
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * Add Product to Shopping cart
   *
   * @param {object} string type object item
   * @returns
   */
  async addToCart({ getters, commit }, { type, item }) {
    if (getters["getCartIsLoaded"] == false) {
      if (getters["getCartServerToken"] == null) {
        const serverToken = await createGuestCart();
        commit("setServerToken", serverToken);
      }
    }
    let retval;
    switch (type) {
      case "SimpleProduct":
        retval = await addSimpleProduct(item);
        break;
      case "ConfigurableProduct":
        retval = await addConfigurableProduct(item);
        break;
      case "BundleProduct":
        retval = await addBundleProduct(item);
        break;

      default:
        retval = false;
        break;
    }
    //add item to cart
    //load the cart
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  async addProductToCart({ getters, commit }, { items }) {
    if (getters["getCartIsLoaded"] == false) {
      if (getters["getCartServerToken"] == null) {
        const serverToken = await createGuestCart();
        commit("setServerToken", serverToken);
      }
    }
    let retval = await addProduct(items);
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * Add shipping address to shopping cart by id
   *
   * @param {object} integer id
   * @returns true or false
   */
  async addShippingAddressById({ commit }, { id }) {
    commit("setFake", null);
    const retval = await setShippingAddressById(id);
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * add shipping method to shopping cart
   *
   * @param {object} string carries string method
   * @returns true or false
   */
  async addShippingMethod({ commit }, { carrier, method }) {
    commit("setFake", null);
    const retval = await setShippingMethodsOnCart(carrier, method);
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * add payment method to shopping cart
   * @returns true or false
   */
  async addPaymentMethod() {
    const retval = await setPaymentMethod();
    return retval;
  },
  /**
   * loadPickupLocations
   * @returns true or false
   */
  async loadPickupLocations({ commit }) {
    const retval = await getPickupLocations();
    commit("setPickupLocations", retval.items);
    return retval;
  },
  /**
   * add shipping address to shopping cart
   *
   * @param {object} object address
   * @returns true or false
   */
  async addShippingAddress({ commit }, { address, reloadCart = true }) {
    commit("setFake", null);
    const retval = await setShippingAddress(address);
    if (retval == true && reloadCart == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },

  async setPickupLocation({ commit, getters }, { code }) {
    commit("setFake", null);
    let address = getters["getShippingAddress"];
    let address_id = getters["getShippingAddressId"];
    const retval = await setPickupLocation(code, address, address_id);
    if (retval != false) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * Add billing address to shopping cart by id
   *
   * @param {object} integer id
   * @returns true or false
   */
  async addBillingAddressById({ commit }, { id }) {
    commit("setFake", null);
    const retval = await setBillingAddressById(id);
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * add billing address to shopping cart
   *
   * @param {object} object address
   * @returns true or false
   */
  async addBillingAddress({ commit }, { address, reloadCart = true }) {
    commit("setFake", null);
    const retval = await setBillingAddress(address);
    if (retval == true && reloadCart == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * add email address to shpping cart
   *
   * @param {object} string email
   * @returns
   */
  async addCartEmail({ commit }, { email }) {
    commit("setFake", null);
    const retval = await setEmailToCart(email);
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },

  /**
   * add coupon code to shopping cart
   *
   * @param {object} string code
   * @returns true or false
   */
  async addCouponCode({ commit }, { code }) {
    commit("setFake", null);
    const retval = await addCouponCode(code);
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  },
  /**
   * Remove coupon code from shopping cart
   *
   * @param {object} string code
   * @returns true or false
   */
  async removeCouponCode({ commit }) {
    commit("setFake", null);
    const retval = await removeCouponCode();
    if (retval == true) {
      store.dispatch("cart/loadCart");
    }
    return retval;
  }
};

const mutations = {
  /**
   * Set shopping cart token
   *
   * @param {object} state
   * @param {string} payload
   * @private
   *
   */
  setServerToken(state, payload) {
    state.cartServerToken = payload;
    if (payload != null) {
      localStorage.setItem("cartServerToken", payload);
    } else {
      localStorage.removeItem("cartServerToken");
    }
  },
  /**
   * set if the shopping cart is loaded
   *
   * @param {object} state
   * @param {boolean} payload
   * @private
   *
   */
  setCartIsLoaded(state, payload) {
    state.cartIsLoaded = payload;
  },
  setPickupLocations(state, payload) {
    state.pickupLocations = payload;
  },
  setComment(state, payload) {
    state.comment = payload;
  },

  /**
   * Set the shopping cart items
   *
   * @param {object} state
   * @param {object} payload
   * @private
   *
   */
  setCartItems(state, payload) {
    state.cartItems = payload;
    if (state.cartItems.length > 0) {
      if (config.cart.minicartCountType === "items") {
        state.cartQuantity = state.cartItems.length;
      } else {
        let qty = 0;
        state.cartItems.forEach((element) => {
          qty = qty + element.quantity;
        });
        state.cartQuantity = qty;
      }
    } else {
      state.cartQuantity = 0;
    }
  },
  /**
   * set shipping address
   *
   * @param {object} state
   * @param {object} payload
   * @private
   *
   */
  setShippingAddress(state, payload) {
    state.cartShippingAddress = payload;
  },
  /**
   * set billing address
   *
   * @param {object} state
   * @param {object} payload
   * @private
   *
   */
  setBillingAddress(state, payload) {
    state.cartBillingAddress = payload;
  },
  /**
   * set shipping methods
   *
   * @param {object} state
   * @param {array} payload
   * @private
   *
   */
  setShippingMethods(state, payload) {
    state.cartShippingMethods = payload;
  },
  /**
   * set payment methods
   *
   * @param {object} state
   * @param {array} payload
   * @private
   */
  setPaymentMethods(state, payload) {
    const obj = {};
    payload.forEach((opt) => {
      const option = {};
      if (opt.additional_fields && opt.additional_fields.length > 0) {
        opt.additional_fields.forEach((element) => {
          option[element.code] = null;
        });
        obj[opt.code] = option;
      }
    });
    state.cartPaymentMethodOptions = obj;
    state.cartPaymentMethods = payload;
  },
  /**
   * set Shipping method
   *
   * @param {object} state
   * @param {object} payload
   * @private
   *
   */
  setShippingMethod(state, payload) {
    state.cartShippingMethod = payload;
  },
  /**
   * set payment method
   *
   * @param {object} state
   * @param {object} payload
   * @private
   *
   */
  setPaymentMethod(state, payload) {
    state.cartPaymentMethod = payload;
  },
  /**
   * set billing address id
   *
   * @param {object} state
   * @param {integer} payload
   * @private
   *
   */
  setBillingAddressId(state, payload) {
    state.cartBillingAddressId = payload;
  },
  /**
   * set shipping address id
   *
   * @param {object} state
   * @param {integer} payload
   * @private
   *
   */
  setShippingAddressId(state, payload) {
    state.cartShippingAddressId = payload;
  },
  /**
   * set email address
   *
   * @param {object} state
   * @param {string} payload
   * @private
   *
   */
  setEmail(state, payload) {
    state.cartEmail = payload;
  },

  /**
   * set free shupping amount
   *
   * @param {object} state
   * @param {double} payload
   * @private
   */
  setFreeShippingAmount(state, payload) {
    state.freeShippingAmount = payload;
  },
  /**
   * set free shipping info
   *
   * @param {object} state
   * @param {object} payload
   * @private
   *
   */
  setFreeShipping(state, payload) {
    state.freeShipping = payload;
  },
  /**
   * a fake set when you don't need any other object in you dispatch
   *
   * @param {object} state
   * @param {any} payload
   * @private
   *
   */
  setFake(state, payload) {
    state.Fake = payload;
  },
  /**
   * set cart prices
   *
   * @param {object} state
   * @param {object} payload
   * @private
   */
  setCartPrices(state, payload) {
    state.cartPrices = payload;
  },
  /**
   * set default shipping
   *
   * @param {object} state
   * @param {object} payload
   * @private
   */
  setDefaultShipping(state, payload) {
    state.defaultShipping = payload;
  },
  /**
   * set shopping cart payment method options
   *
   * @param {object} state
   * @param {array} payload
   * @private
   */
  setCartPaymentMethodOptions(state, payload) {
    state.cartPaymentMethodOptions = payload;
  },
  /**
   * update shopping cart payment method options
   *
   * @param {object} state
   * @param {object} payload
   * @private
   */
  updateCartPaymentMethodOptions(state, payload) {
    state.cartPaymentMethodOptions[payload.key][payload.sub] = payload.value;
  },
  /**
   * update shopping cart delivery time information
   *
   * @param {object} state
   * @param {object} payload
   * @private
   */
  setCartDeliveryTime(state, payload) {
    state.delivery_time = payload;
  },
  /**
   * update shopping cart delivery time information
   *
   * @param {object} state
   * @param {object} payload
   * @private
   */
  setAppliedCouponCodes(state, payload) {
    state.applied_coupons = payload;
  },
  /**
   * update shopping cart delivery time information
   *
   * @param {object} state
   * @param {object} payload
   * @private
   */
  setStoreValidation(state, payload) {
    for (const property in payload) {
      state.storeValidation[property] = payload[property];
    }
  },
  /**
   * Should an account be created on checkout?
   *
   * @param {object} state
   * @param {bool} payload
   * @private
   */
  setCreateAccount(state, payload) {
    state.createAccount = payload
  },
  /**
  * Store password until account is created
  *
  * @param {object} state
  * @param {string} payload
  * @private
  */
  setAccountPassword(state, payload) {
    state.accountPassword = payload
  }
};

const getters = {
  getCartServerToken: (state) => state.cartServerToken,
  getCartItems: (state) => state.cartItems,
  getCartIsLoaded: (state) => state.cartIsLoaded,
  getShippingAddress: (state) => state.cartShippingAddress,
  getBillingAddress: (state) => state.cartBillingAddress,
  getShippingMethods: (state) => state.cartShippingMethods,
  getBillingAddressId: (state) => state.cartBillingAddressId,
  getShippingAddressId: (state) => state.cartShippingAddressId,
  getPaymentMethods: (state) => state.cartPaymentMethods,
  getPaymentMethodOptions: (state) => state.cartPaymentMethodOptions,
  getShippingMethod: (state) => state.cartShippingMethod,
  getPaymentMethod: (state) => state.cartPaymentMethod,
  getEmail: (state) => state.cartEmail,
  getComment: (state) => state.comment,
  getFreeShippingAmount: (state) => {
    let subTotal = 0;
    if (typeof state.cartPrices.subtotal_including_tax != "undefined") {
      subTotal = state.cartPrices.subtotal_including_tax.value;
    }
    let freeS = state.freeShippingAmount - subTotal;
    if (freeS < 0) {
      freeS = 0;
    }
    return freeS;
  },
  getCartPrices: (state) => state.cartPrices,
  getDefaultShipping: (state) => state.defaultShipping,
  getItemsTotalQuantity: (state) => state.cartQuantity,
  getPickupLocations: (state) => state.pickupLocations,
  getCartDeliveryTime: (state) => state.delivery_time,
  getAppliedCouponCodes: (state) => state.applied_coupons,
  getStoreValidation: (state) => state.storeValidation,
  getCreateAccount: (state) => state.createAccount,
  getAccountPassword: (state) => state.accountPassword
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
