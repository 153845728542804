import { loadSliderByIdentifiers } from "@storefront/core/data-resolver/sliders";
import { Logger } from "@storefront/core/lib/logger";
const state = () => ({
  items: [],
});

const actions = {
  async single({ getters, commit }, { key = "identifier", value = "" }) {
    const slider = getters.findSliders({ key, value });

    if (!slider) {
      const blockResponse = await loadSliderByIdentifiers([value]).catch(
        (e) => {
          Logger.error(
            "loadSliderByIdentifiers",
            "sliders store actions single",
            e
          )();
          throw e;
        }
      );
      if (blockResponse.items.length > 0) {
        commit("setSlider", blockResponse.items[0]);
        return blockResponse.items[0];
      }
    }
    return slider[0];
  },
  async multiple({ commit }, { value = [] }) {
    const blockResponse = await loadSliderByIdentifiers(value).catch((e) => {
      Logger.error(
        "loadSliderByIdentifiers",
        "sliders store actions multiple",
        e
      )();
      throw e;
    });
    if (blockResponse.length > 0) {
      blockResponse.forEach((element) => {
        commit("setSlider", element);
      });

      return blockResponse;
    }

    //return cmsBlock[0];
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  setSlider(state, data) {
    if (typeof data == "object") {
      const record = state.items.findIndex(
        (c) => c.identifier === data.identifier
      );
      if (record != -1) {
        state.items.splice(record, 1);
      }
      state.items.push(data);
    }
  },
};

const getters = {
  getSliderByIdentifier: (state) => (identifier) =>
    state.items.find(
      (item) => typeof item === "object" && item.identifier === identifier
    ),
  getSlidesByIdentifier: (state) => (identifier) => {
    const slider = state.items.find(
      (item) => typeof item === "object" && item.identifier === identifier
    );

    if (typeof slider != "undefined") {
      return slider.slides;
    } else {
      return [];
    }
  },
  findSliders: (state) => ({ key, value }) =>
    state.items.filter((item) => item[key] === value),
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
