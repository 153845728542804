import { loadForms } from "@storefront/core/data-resolver/forms";
//import { Logger } from "@storefront/core/lib/logger";
const state = () => ({
  contactForm: null,
  productForm: null,
  categoryForm: null,
});

const actions = {
  /**
   * Load the dynamic forms
   *
   */
  loadForms({ dispatch }) {
    dispatch("loadContactForm");
    dispatch("loadProductForm");
    dispatch("loadCategoryForm");
  },

  /**
   * Load contact form
   *
   */
  async loadContactForm({ commit }) {
    const form = await loadForms("contact");
    commit("setContactForm", form);
  },
  /**
   * Load product form
   *
   */
  async loadProductForm({ commit }) {
    const form = await loadForms("product");
    commit("setProductForm", form);
  },
  /**
   * Load category form
   *
   */
  async loadCategoryForm({ commit }) {
    const form = await loadForms("category");
    commit("setCategoryForm", form);
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  /**
   * set contact form
   *
   * @param {object} data
   */
  setContactForm(state, data) {
    state.contactForm = data;
  },
  /**
   * set product form
   *
   * @param {object} data
   */
  setProductForm(state, data) {
    state.productForm = data;
  },
  /**
   * set category form
   *
   * @param {object} data
   */
  setCategoryForm(state, data) {
    state.categoryForm = data;
  },
};

const getters = {
  getContactForm: (state) => state.contactForm,
  getProductForm: (state) => state.productForm,
  getCategoryForm: (state) => state.categoryForm,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
