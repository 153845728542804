//import { Logger } from "@storefront/core/lib/logger";
const state = () => ({
  current: null, // shown product
  routes: [],
});

const actions = {};

const mutations = {
  /**
   * set breadcrumbs
   * @param {object} state
   * @param {object} payload
   */
  set(state, payload) {
    state.routes = payload.routes;
    state.current = payload.current;
  },
};

const getters = {
  getBreadcrumbsRoutes: (state) => state.routes,
  getBreadcrumbsCurrent: (state) => state.current,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
