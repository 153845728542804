import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import config from "@config";
// import store from "@/store";
import axios from "axios";
import graphql from "@graphql";

const state = () => ({
  bestsellerProducts: [],
  aw_source: null,
});
const actions = {
  async loadBestsellerProducts({ commit }, { category, hello_retail_id }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    let query =
      '{ topHierarchies(categoryName:"' +
      category +
      '",pageType: "category",pageSize: 10, currentPage: 1) {';
    query =
      query +
      graphql.queryFields.productOverview.replace(
        "__typename",
        '__typename aw_source(pageType: "category" trackingUserId:"' +
        hello_retail_id +
        '" requestType: "topHierarchies" categoryName:"' +
        category +
        '")'
      ) +
      "} }";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadBestSellerProducts", "home", e)();
      throw e;
    });
    if (
      retval &&
      retval.data &&
      retval.data.data &&
      retval.data.data.topHierarchies
    ) {
      const products = retval.data.data.topHierarchies;
      if (products.items && products.items.length > 0) {
        products.items.forEach((item) => {
          const aw_source = item.aw_source;
          const sku = item.sku;
          commit("setAwSource", { sku, aw_source });
        });
      }
      commit("setBestsellerProducts", products.items);
    } else {
      commit("setBestsellerProducts", []);
    }
  },
};

const mutations = {
  setBestsellerProducts(state, payload) {
    state.bestsellerProducts = payload;
  },
  setAwSource(state, payload) {
    let awSource = JSON.parse(localStorage.getItem("aw_source")) ?? [];
    awSource.push(payload);
    localStorage.setItem("aw_source", JSON.stringify(awSource));
  },
};

const getters = {
  getBestsellerProducts: (state) => state.bestsellerProducts,
  getAwSource: (state) => state.aw_source,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
