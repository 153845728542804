import Vue from "vue";
import mainStore from "@/store";
import createRouter from "@storefront/core/router";

import "@/registerServiceWorker";

import config from "@config";
import VueMeta from "vue-meta";
import VueCookie from "vue-cookies";
import VueLazyload from "vue-lazyload";
import VueGtm from "@gtm-support/vue2-gtm";
import VueSocialSharing from 'vue-social-sharing'
// import VueCarousel from 'vue-carousel';

import { sync } from "vuex-router-sync";
import { i18n } from "@storefront/core/i18n";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueMatchHeights from 'vue-match-heights';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueRememberScrollCache from 'vue-remember-scroll-cache'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueLazyload);
Vue.use(VueMeta);
Vue.use(VueCookie);
Vue.use(VueSocialSharing);
Vue.use(VueMatchHeights);
Vue.use(VueRememberScrollCache)
// Vue.use(VueCarousel);

dom.watch();
library.add(fab);
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

const currentTemplate = config.template.name;

const App = () => import(`./${currentTemplate}/App.vue`);

require(`./${currentTemplate}/scss/main.scss`);

// console.log("Current Template", currentTemplate);

const store = mainStore;
const router = createRouter();

Vue.use(VueGtm, {
  id: config.gtmId,
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false,
  enabled: false,
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router,
});

sync(store, router);
export default () => {
  return new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  });
};

export { router, store };
