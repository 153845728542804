import config from '@config';
/**
 * running on server (true or false)
 */
export const isServer = typeof window === "undefined";
export default {
    /**
 *
 * @param {float|int} amount
 * @param {boolean} showCurrencySign
 * @returns {string}
 */
    formatCurrency(amount, showCurrencySign = true) {
        var formatter;
        if (showCurrencySign) {
            formatter = new Intl.NumberFormat(config.currency.locale, {
                style: "currency",
                currency: config.currency.currency,
            });
        } else {
            formatter = new Intl.NumberFormat(config.currency.locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return config.currency.useDash
                ? formatter.format(amount).replace(/,00$/, ",-")
                : formatter.format(amount);
        }
        return config.currency.useDash
            ? formatter.format(amount).replace(/,00$/, ",-")
            : formatter.format(amount);
    },
}
