export default {
  plugins: [
    "@uvue/core/plugins/asyncData",
    [
      "@uvue/core/plugins/vuex",
      {
        onHttpRequest: true,
      },
    ],
    "@uvue/core/plugins/middlewares",
    "@uvue/core/plugins/errorHandler",
    // HTTP client to get data from API
    "@storefront/core/plugins/httpClient"

  ],
  css: {
    normal: "extract",
    vue: "extract",
  },
};
