//import { Logger } from "@storefront/core/lib/logger";
import config from "@config";

export function cleanPath(to) {
  let path = to.path;
  let split = to.path.split("/");
  if (config.languages.available.includes(split[1])) {
    if (split.length > 1) {
      split = split.splice(2);
      path = "/" + split.join("/");
    }
  }
  return path;
}

export function extractFilters(to) {

  const routeQuery = to.query;

  const allFilters = {};
  if (routeQuery["sort"]) allFilters.sort = routeQuery.sort;
  if (routeQuery["page"]) allFilters.page = routeQuery.page;
  if (routeQuery["page-size"]) allFilters.pageSize = routeQuery["page-size"];

  const filters = {};
  
  Object.keys(routeQuery).forEach((element) => {
    if (element.startsWith("filter-")) {
      const attribute_code = element.replace("filter-", "");
      filters[attribute_code] = routeQuery[element];
    }
  });
  if (Object.keys(filters).length) allFilters["filters"] = filters;

  return Object.keys(allFilters).length ? allFilters : false;
}
