import axios from "axios";
import config from "@config";
import graphql from "@graphql";
import store from "@/store";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";

/**
 * get product based on url
 *
 * @param {string} url
 * @returns return products object
 */
export async function getProductByUrl( url ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{products(filter: {url_key:{eq:"' +
    url +
    '"}})' +
    graphql.queryFields.productDetail +
    "}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
    //data: { query: query },
  } ).catch( ( e ) => {
    Logger.error( "getProductByUrl", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get product based on url
 *
 * @param {string} url
 * @returns return products object
 */
export async function getProductBySku( sku ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{products(filter: {sku:{eq:"' +
    sku +
    '"}})' +
    graphql.queryFields.productDetail +
    "}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
    //data: { query: query },
  } ).catch( ( e ) => {
    Logger.error( "getProductBySku", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 *
 * @param {string} sku
 * @param {string} type
 * @returns return products details based on product type object
 */
export async function getProductDetails( sku, type ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  let query = '{products(filter: {sku:{eq:"' + sku + '"}})';
  if ( type == "SimpleProduct" ) {
    query = query + graphql.queryFields.productSimple;
  } else if ( type == "ConfigurableProduct" ) {
    query = query + graphql.queryFields.productConfigurable;
  } else if ( type == "BundleProduct" ) {
    query = query + graphql.queryFields.productBundle;
  } else if ( type == "GroupedProduct" ) {
    query = query + graphql.queryFields.productGrouped;
  }

  query = query + "}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
    //data: { query: query },
  } ).catch( ( e ) => {
    Logger.error( "getProductByUrl", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get related product by sku
 * @param {string} sku
 * @returns related products array
 */
export async function getProductRelated( sku ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{products(filter: {sku:{eq:"' +
    sku +
    '"}})' +
    graphql.queryFields.productRelated +
    "}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
    //data: { query: query },
  } ).catch( ( e ) => {
    Logger.error( "getProductByUrl", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get cross sell product by sku
 * @param {string} sku
 * @returns cross sell products array
 */
export async function getProductCrossSell( sku ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{products(filter: {sku:{eq:"' +
    sku +
    '"}})' +
    graphql.queryFields.productCrossSell +
    "}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
    //data: { query: query },
  } ).catch( ( e ) => {
    Logger.error( "getProductByUrl", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get upsell product by sku
 * @param {string} sku
 * @returns upsell products array
 */
export async function getProductUpSell( sku ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{products(filter: {sku:{eq:"' +
    sku +
    '"}})' +
    graphql.queryFields.productUpSell +
    "}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
    //data: { query: query },
  } ).catch( ( e ) => {
    Logger.error( "getProductByUrl", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get product object by id
 * @param {integer} id
 * @returns return product object
 */
export async function getProductById( id ) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{products(filter: {id:{eq:"' +
    id +
    '"}})' +
    " { items { sku name type_id attribute_set_id image {url} small_image {url} thumbnail {url} price { regularPrice{  amount{currency value} adjustments {amount{value currency} code description} } minimalPrice{  amount{currency value} adjustments {amount{value currency} code description} } maximalPrice{  amount{currency value} adjustments {amount{value currency} code description} } } meta_title meta_keyword meta_description media_gallery{url label} new_from_date } }}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductById", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get brandslider
 *
 * @returns array of brands
 */
export async function getBrandSlider() {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{ getBrands( filter: { showInSlider: true }, orderBy: "title", order: "ASC", ) { items { category {id url_key url_path } showInSlider positionInSlider title shortDescription description logo image metaTitle metaDescription metaKeywords urlKey } } }';
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getBrandSlider", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.getBrands;
}

/**
 * get Productfilter for category page
 *
 * @param {integer} id
 * @returns products and filters object
 */
export async function getProductFiltersByCategory(
  id,
  page_size = 12,
  filters = []
) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  let query = " { products(pageSize: " + page_size;
  query += ' filter: {category_id: ';

  let subcategoryFilterActive = filters.find( filter => filter.attribute_code == 'subcategory' );

  if ( !subcategoryFilterActive ) {
    query += '{eq: "' + id + '"} ';
  } else {
    query += '{in: ' + JSON.stringify( subcategoryFilterActive.values.map( v => v.toString() ) ) + '} ';
  }

  for ( const filter of filters.filter( filter => filter.attribute_code != 'subcategory' ) ) {
    if ( filter.attribute_code === "price" ) {
      query +=
        'price: { from: "' +
        filter.values[ 0 ] +
        '" to: "' +
        filter.values[ 1 ] +
        '" } ';
    } else {
      query +=
        filter.attribute_code +
        ": {in: " +
        JSON.stringify( filter.values ) +
        "} ";
    }
  }
  query = query + "})";

  query =
    query +
    "{ " +
    graphql.queryFields.pageInfo +
    " " +
    graphql.queryFields.aggregations +
    " " +
    "} }";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error(
      "getProductFiltersByCategoryId",
      "data-resolver products",
      e
    )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get ProductFilters for filtered category
 *
 * @param {integer} id
 * @param {integer} page_size
 * @param {object}
 * @returns products and filters object
 */
export async function getProductFiltersByFilteredCategory(
  id,
  curr_page,
  page_size,
  { sort, filters }
) {
  const lang = getCurrentLanguage();

  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  let query =
    " { products(pageSize: " +
    page_size +
    " currentPage: " +
    curr_page +
    ' filter: {category_id: ';

  let subcategoryFilterActive = filters.find( filter => filter.attribute_code == 'subcategory' );

  if ( !subcategoryFilterActive ) {
    query += '{eq: "' + id + '"} ';
  } else {
    query += '{in: ' + JSON.stringify( subcategoryFilterActive.values.map( v => v.toString() ) ) + '} ';
  }

  for ( const filter of filters.filter( filter => filter.attribute_code != 'subcategory' ) ) {
    if ( filter.attribute_code === "price" ) {
      query +=
        'price: { from: "' +
        filter.values[ 0 ] +
        '" to: "' +
        filter.values[ 1 ] +
        '" } ';
    } else {
      query +=
        filter.attribute_code +
        ": {in: " +
        JSON.stringify( filter.values ) +
        "} ";
    }
  }

  query += "} ";

  if ( sort != null ) {
    query += " sort: { " + sort.sortBy + ": " + sort.direction + " } ";
  }

  query +=
    ") { " +
    graphql.queryFields.pageInfo +
    " " +
    graphql.queryFields.aggregations +
    " " +
    graphql.queryFields.productOverview +
    " } }";

  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error(
      "getProductFiltersByFilteredCategory",
      "data-resolver products",
      e
    )();
    throw e;
  } );

  return retval.data.data.products;
}

/**
 * get products by category id
 *
 * @param {integer} id
 * @param {integer} page
 * @returns products object array
 */
export async function getProductByCategoryId( id, page ) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];
  const defaultsort = config.sortDefault;

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  let end_query = "";

  let query = "{ products(pageSize: 12 currentPage: " + page;

  if ( typeof defaultsort === "object" ) {
    query +=
      " sort: { " + defaultsort.sortBy + ": " + defaultsort.direction + " }";
  }

  query += ' filter: {category_id: {eq: "' + id + '"}';

  if ( store.getters[ "partfinder/getType" ] != null ) {
    query = query + " partfinder: {";
    query =
      query + ' type_id: {eq: "' + store.getters[ "partfinder/getType" ] + '"}';
    end_query = "}";
  }
  if ( store.getters[ "partfinder/getBrand" ] != null ) {
    query =
      query + ' brand_id: {eq: "' + store.getters[ "partfinder/getBrand" ] + '"}';
  }
  if ( store.getters[ "partfinder/getModel" ] != null ) {
    query =
      query + ' model_id: {eq: "' + store.getters[ "partfinder/getModel" ] + '"}';
  }
  if ( store.getters[ "partfinder/getYear" ] != null ) {
    query =
      query + ' year_id: {eq: "' + store.getters[ "partfinder/getYear" ] + '"}';
  }
  if ( store.getters[ "partfinder/getCilinder" ] != null ) {
    query =
      query +
      ' cilinder_id: {eq: "' +
      store.getters[ "partfinder/getCilinder" ] +
      '"}';
  }

  query = query + end_query + "})";
  query =
    query +
    "{ " +
    graphql.queryFields.pageInfo +
    " " +
    graphql.queryFields.productOverview +
    "} }";

  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductByCategoryId", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get products by filtered category
 *
 * @param {integer} id
 * @param {integer} page
 * @param {integer} page_size
 * @param {object} filters
 * @returns products object array
 */
export async function getProductByFilteredCategory(
  id,
  curr_page,
  page_size,
  { sort, filters }
) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  let query =
    " { products(pageSize: " +
    page_size +
    " currentPage: " +
    curr_page +
    ' filter: {category_id: ';

  let subcategoryFilterActive = filters.find( filter => filter.attribute_code == 'subcategory' );

  if ( !subcategoryFilterActive ) {
    query += '{eq: "' + id + '"} ';
  } else {
    query += '{in: ' + JSON.stringify( subcategoryFilterActive.values.map( v => v.toString() ) ) + '} ';
  }

  for ( const filter of filters.filter( filter => filter.attribute_code != 'subcategory' ) ) {
    if ( filter.attribute_code === "price" ) {
      query +=
        'price: { from: "' +
        filter.values[ 0 ] +
        '" to: "' +
        filter.values[ 1 ] +
        '" } ';
    } else {
      query +=
        filter.attribute_code +
        ": {in: " +
        JSON.stringify( filter.values ) +
        "} ";
    }
  }

  query += "} ";

  if ( sort != null ) {
    query += " sort: { " + sort.sortBy + ": " + sort.direction + " }";
  }

  query += " ) { " + graphql.queryFields.productOverview + " } }";

  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductByFilteredCategory", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get products based on search string
 *
 * @param {string} filter
 * @returns products array
 */
export async function getProductsBySearchPreview( filter, signal, page_size = 5 ) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    " { products(search: " +
    JSON.stringify(
      filter
        .replace( /\s+/g, " " )
        .trim()
        .toLowerCase()
    ) +
    " pageSize: " + page_size + ")" +
    graphql.queryFields.productSearchPrev +
    "}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
    signal: signal,
  } ).catch( ( e ) => {
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get products filters based on search string
 *
 * @param {string} filter
 * @returns  product filters object
 */
export async function getProductFiltersBySearch(
  search,
  page_size = 12,
  filters = []
) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  let query =
    " { products(pageSize: " +
    page_size +
    " search: " +
    JSON.stringify(
      search
        .replace( /\s+/g, " " )
        .trim()
        .toLowerCase()
    );

  if ( filters.length > 0 ) {
    query += " filter: {  ";
    for ( const filter of filters ) {
      if ( filter.attribute_code === "price" ) {
        query +=
          'price: { from: "' +
          filter.values[ 0 ] +
          '" to: "' +
          filter.values[ 1 ] +
          '" } ';
      } else {
        query +=
          filter.attribute_code +
          ": {in: " +
          JSON.stringify( filter.values ) +
          "} ";
      }
    }
    query += " } ";
  }
  query +=
    ") { " +
    graphql.queryFields.pageInfo +
    " " +
    graphql.queryFields.aggregations +
    " } }";

  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductFiltersBySearch", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get products filters based on search string and filters
 *
 * @param {string} search
 * @param {integer} page_size
 * @param {array} filters
 * @returns  product filters object
 */
export async function getProductFiltersByFilteredSearch(
  search,
  curr_page,
  page_size,
  { sort, filters }
) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  let query =
    " { products(pageSize: " +
    page_size +
    " currentPage: " +
    curr_page +
    " search: " +
    JSON.stringify(
      search
        .replace( /\s+/g, " " )
        .trim()
        .toLowerCase()
    );

  if ( sort != null ) {
    query += " sort: { " + sort.sortBy + ": " + sort.direction + " } ";
  }

  if ( filters.length > 0 ) {
    query += " filter: {  ";
    for ( const filter of filters ) {
      if ( filter.attribute_code === "price" ) {
        query +=
          'price: { from: "' +
          filter.values[ 0 ] +
          '" to: "' +
          filter.values[ 1 ] +
          '" } ';
      } else {
        query +=
          filter.attribute_code +
          ": {in: " +
          JSON.stringify( filter.values ) +
          "} ";
      }
    }
    query += " } ";
  }

  query +=
    " ) { " +
    graphql.queryFields.pageInfo +
    " " +
    graphql.queryFields.aggregations +
    " " +
    graphql.queryFields.productOverview +
    " } }";

  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error(
      "getProductFiltersByFilteredSearch",
      "data-resolver products",
      e
    )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get products based on search string and filters
 *
 * @param {string} filter
 * @param {integer} page
 * @param {integer} page_size
 * @param {object} filters
 * @returns  product filters object
 */
export async function getProductByFilteredSearch(
  search,
  curr_page,
  page_size,
  { sort, filters }
) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  let query =
    " { products(pageSize: " +
    page_size +
    " currentPage: " +
    curr_page +
    " search:" +
    JSON.stringify(
      search
        .replace( /\s+/g, " " )
        .trim()
        .toLowerCase()
    );

  if ( filters.length > 0 ) {
    query += " filter: {  ";
    for ( const filter of filters ) {
      if ( filter.attribute_code === "price" ) {
        query +=
          'price: { from: "' +
          filter.values[ 0 ] +
          '" to: "' +
          filter.values[ 1 ] +
          '" } ';
      } else {
        query +=
          filter.attribute_code +
          ": {in: " +
          JSON.stringify( filter.values ) +
          "} ";
      }
    }
    query += "} ";
  }

  if ( sort != null ) {
    query += " sort: { " + sort.sortBy + ": " + sort.direction + " }";
  }

  query += " ) { " + graphql.queryFields.productOverview + " } }";

  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductByFilteredSearch", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get THe review rating meta data
 * @returns review rating met data object
 */
export async function productReviewRatingsMetadata() {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ productReviewRatingsMetadata { items { id name values { value_id value } } }}";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "productReviewRatingsMetadata", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.productReviewRatingsMetadata;
}

/**
 *
 * @returns
 */
export async function getProductFiltersByPartfinder() {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  let query = " { partfinderSearch( partFilters: {";
  if ( store.getters[ "partfinder/getType" ] != null ) {
    query =
      query + ' type_id: {eq: "' + store.getters[ "partfinder/getType" ] + '"}';
  }
  if ( store.getters[ "partfinder/getBrand" ] != null ) {
    query =
      query + ' brand_id: {eq: "' + store.getters[ "partfinder/getBrand" ] + '"}';
  }
  if ( store.getters[ "partfinder/getModel" ] != null ) {
    query =
      query + ' model_id: {eq: "' + store.getters[ "partfinder/getModel" ] + '"}';
  }
  if ( store.getters[ "partfinder/getYear" ] != null ) {
    query =
      query + ' year_id: {eq: "' + store.getters[ "partfinder/getYear" ] + '"}';
  }
  if ( store.getters[ "partfinder/getCilinder" ] != null ) {
    query =
      query +
      ' cilinder_id: {eq: "' +
      store.getters[ "partfinder/getCilinder" ] +
      '"}';
  }

  query = query + "})";

  query =
    query +
    " { total_count aggregations { min_value max_value attribute_code count label options { count label value } } } }";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductFiltersBySearch", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.partfinderSearch;
}

export async function getProductByPartfinder( page ) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  let query =
    " { partfinderSearch(pageSize: 12 currentPage: " +
    page +
    " partFilters: { ";
  if ( store.getters[ "partfinder/getType" ] != null ) {
    query =
      query + ' type_id: {eq: "' + store.getters[ "partfinder/getType" ] + '"}';
  }
  if ( store.getters[ "partfinder/getBrand" ] != null ) {
    query =
      query + ' brand_id: {eq: "' + store.getters[ "partfinder/getBrand" ] + '"}';
  }
  if ( store.getters[ "partfinder/getModel" ] != null ) {
    query =
      query + ' model_id: {eq: "' + store.getters[ "partfinder/getModel" ] + '"}';
  }
  if ( store.getters[ "partfinder/getYear" ] != null ) {
    query =
      query + ' year_id: {eq: "' + store.getters[ "partfinder/getYear" ] + '"}';
  }
  if ( store.getters[ "partfinder/getCilinder" ] != null ) {
    query =
      query +
      ' cilinder_id: {eq: "' +
      store.getters[ "partfinder/getCilinder" ] +
      '"}';
  }

  query = query + "})";

  query =
    query +
    "{ " +
    graphql.queryFields.pageInfo +
    " " +
    graphql.queryFields.productOverview +
    " } }";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductBySearch", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.partfinderSearch;
}

/**
 * post product review
 * @param {object} inp
 * @returns review or false
 */
export async function createProductReview( inp ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];
  let headers = {};
  if ( store.getters[ "user/getIsLoggedIn" ] != false ) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters[ "user/getUserToken" ],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    "mutation {createProductReview (input: {" +
    'sku:"' +
    inp.sku +
    '" ' +
    " nickname: " +
    JSON.stringify( inp.nickname ) +
    " summary: " +
    JSON.stringify( inp.summary ) +
    " text: " +
    JSON.stringify( inp.text ) +
    " ratings: [";
  inp.ratings.forEach( ( element ) => {
    query =
      query +
      "{ id: " +
      JSON.stringify( element.id ) +
      ", value_id: " +
      JSON.stringify( element.value_id ) +
      "} ";
  } );

  query =
    query +
    "]}){ review { nickname summary text average_rating ratings_breakdown { name value } } }}";
  const retval = await axios( {
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  } ).catch( ( e ) => {
    Logger.error( "createProductReview", "data-resolver carts", e )();
    console.log( e );
    throw e;
  } );
  if ( retval.data.data.createProductReview != null ) {
    return retval.data.data.createProductReview;
  } else {
    if ( retval.data.errors != null ) {
      retval.data.errors.forEach( ( element ) => {
        if ( store.getters[ "user/getIsLoggedIn" ] != false ) {
          if ( element.extensions.category === "graphql-authorization" ) {
            return store.dispatch( "user/sessionExpired" );
          }
        }
      } );
    }
    return false;
  }
}

/**
 *
 * @param {string} filter
 * @returns filter object
 */
export async function getProductFiltersByFilter( filter ) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    " { products(pageSize: 12 filter:  " +
    filter +
    ") { total_count aggregations { min_value max_value attribute_code count label options { count label value } } } }";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductFiltersBySearch", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * Get product by filter
 *
 * @param {string} filter
 * @param {int} page
 * @returns
 */
export async function getProductByFilter( filter, page ) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    " { products(pageSize: 12 currentPage: " +
    page +
    " filter: " +
    filter +
    "){ " +
    graphql.queryFields.pageInfo +
    " " +
    graphql.queryFields.productOverview +
    " } }";
  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductBySearch", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get products by filter
 *
 * @param {integer} id
 * @param {integer} page
 * @returns products object array
 */
export async function getProductsByFilter( filter ) {
  const lang = getCurrentLanguage();
  //Needs to become variable based on language
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  let query = " { products(pageSize: 12 " + " filter: {" + filter + "}";
  query = query + ")";
  query =
    query +
    "{ " +
    graphql.queryFields.pageInfo +
    " " +
    graphql.queryFields.productOverview +
    " } }"; //Functie wordt momenteel niet gebruikt. Dus aggregations niet nodig.

  const retval = await axios( {
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent( query ),
    method: "GET",
    headers: headers,
  } ).catch( ( e ) => {
    Logger.error( "getProductByCategoryId", "data-resolver products", e )();
    throw e;
  } );
  return retval.data.data.products;
}

/**
 * get cross sell product by sku
 * @param {array} skus
 * @returns cross sell products array
 */
export async function getCartCrossSell( skus ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  if ( Array.isArray( skus ) && skus.length > 0 ) {
    const query =
      '{products(filter: {sku:{in:' +
      JSON.stringify( skus ) +
      '}})' +
      graphql.queryFields.productCrossSell +
      "}";
    const retval = await axios( {
      url: config.shop.graphQLURL + "?query=" + query,
      method: "GET",
      headers: headers,
      //data: { query: query },
    } ).catch( ( e ) => {
      Logger.error( "getCartCrossSell", "data-resolver products", e )();
      throw e;
    } );
    let crosssell_products = [];
    if ( retval.data.data.products && retval.data.data.products.items ) {
      retval.data.data.products.items.forEach( ( item ) => {
        if ( item && item.crosssell_products && item.crosssell_products.length > 0 ) {
          item.crosssell_products.forEach( ( product ) => {
            crosssell_products.push( product );
          } );
        }
      } );
    }
    return crosssell_products;
  } else {
    return [];
  }
}

/**
 * get cross sell product by sku
 * @param {array} skus
 * @returns cross sell products array
 */
export async function getCartRelated( skus ) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[ lang ];
  const storeview = storelang[ "storeview" ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  if ( Array.isArray( skus ) && skus.length > 0 ) {
    const query =
      '{products(filter: {sku:{in:' +
      JSON.stringify( skus ) +
      '}})' +
      graphql.queryFields.productRelated +
      "}";
    const retval = await axios( {
      url: config.shop.graphQLURL + "?query=" + query,
      method: "GET",
      headers: headers,
      //data: { query: query },
    } ).catch( ( e ) => {
      Logger.error( "getCartRelated", "data-resolver products", e )();
      throw e;
    } );
    let related_products = [];
    if ( retval.data.data.products && retval.data.data.products.items ) {
      retval.data.data.products.items.forEach( ( item ) => {
        if ( item && item.related_products && item.related_products.length > 0 ) {
          item.related_products.forEach( ( product ) => {
            related_products.push( product );
          } );
        }
      } );
    }
    return related_products;
  } else {
    return [];
  }
}
