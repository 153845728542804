import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
//import store from "@/store";

/**
 * Load the general message
 *
 * @returns general message object
 */
export async function loadGeneralMsg() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query = " { globalMessages { background_color text_color text }}";

  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadCategoryById", "data-resolver categories", e)();
    throw e;
  });
  if (retval.data.data.globalMessages != null) {
    return retval.data.data.globalMessages;
  } else {
    return false;
  }
}
