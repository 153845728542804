import { Logger } from "@storefront/core/lib/logger";
import { getProductDetails } from "@storefront/core/data-resolver/products";
import { getCurrentLanguage, i18n } from "@storefront/core/i18n";
import config from "@config";
import store from "@/store";
import axios from "axios";
import graphql from "@graphql";
import coreProduct from "@storefront/core/modules/catalog/store/product";

const state = () => ({
  ...coreProduct.state(),
  currentConfig: null,
  currentCustomMeasures: [],
  currentProdBundle: [],
  currentProdBundleOptions: {},
  currentProdSelectedBundles: {},
  currentProdSelectedBundleChildSku: {},
  crossSellProducts: [],
  upSellProducts: [],
  aw_source: null,
});

const actions = {
  ...coreProduct.actions,

  async loadConfigProduct({ commit }, { sku, type }) {
    const products = await getProductDetails(sku, type).catch((e) => {
      Logger.error("getProductDetails", "product store actions load", e)();
      throw e;
    });
    const product = products.items[0];
    commit("setCurrentConfig", product);

    const prodOptions = [];
    if (product.configurable_options.length == 1) {
      const values = [];
      product.variants.forEach((element) => {
        const value = {
          label: element.attributes[0].label,
          value_index: element.product.sku,
          price: element.product.price_range.minimum_price.final_price.value.toFixed(
            2
          ),
        };
        values.push(value);
      });
      const prodOption = {
        index: 0,
        attribute_code: product.configurable_options[0].attribute_code,
        label: product.configurable_options[0].label,
        id: product.configurable_options[0].id,
        choice: null,
        values: values,
      };
      prodOptions.push(prodOption);

      if (product.configurable_options[0].attribute_code == "fkv_maten") {
        const cvalues = [];
        if (product.size_chart != null) {
          if (product.size_chart.sizes && product.size_chart.sizes.length != null) {
            product.variants.forEach((element) => {
              const found = product.size_chart.sizes.find((v) => {
                if (v.from.option_id == element.attributes[0].value_index) {
                  return true;
                }
              });
              if (found != null) {
                const cvalue = {
                  label: found.to.label,
                  value_index: element.product.sku,
                };
                cvalues.push(cvalue);
              }
            });
          }
        }
        commit("setCurrentCustomMeasures", cvalues);
      }
    } else {
      product.configurable_options.forEach((option, index) => {
        if (index == 0) {
          const prodOption = {
            index: index,
            attribute_code: option.attribute_code,
            label: option.label,
            id: option.id,
            choice: null,
            values: option.values,
          };
          prodOptions.push(prodOption);
          if (option.attribute_code == "fkv_maten") {
            const cvalues = [];
            if (product.size_chart.sizes.length != null) {
              option.values.forEach((element) => {
                const found = product.size_chart.sizes.find((v) => {
                  if (v.from.option_id == element.value_index) {
                    return true;
                  }
                });
                if (found != null) {
                  const cvalue = {
                    label: found.to.label,
                    value_index: element.value_index,
                  };
                  cvalues.push(cvalue);
                }
              });
              commit("setCurrentCustomMeasures", cvalues);
            }
          }
        } else {
          const prodOption = {
            index: index,
            attribute_code: option.attribute_code,
            label: option.label,
            choice: null,
            id: option.id,
            values: [],
          };
          prodOptions.push(prodOption);
        }
      });
    }
    let sortArray = [];
    product.configurable_options[0].values.forEach((v) => {
      sortArray.push(v.label);
    })
    prodOptions.forEach((prodOption) => {
      if (prodOption.attribute_code == 'fkv_maten') {
        prodOption.values.sort((a, b) => sortArray.indexOf(a.label) - sortArray.indexOf(b.label))
      }
    })
    commit("setCurrentOptions", prodOptions);
    commit("setCurrentChildSku", null);
  },
  /**
   * Load the bundle product
   * @param {object} string sku, string type
   *
   */
  async loadBundleProduct({ commit }, { sku, type }) {
    const products = await getProductDetails(sku, type).catch((e) => {
      Logger.error("getProductDetails", "product store actions load", e)();
      throw e;
    });
    const selectedBundles = {};
    const selectedBundleChildSku = {};

    const bundleOptions = products.items[0].items;
    commit("setCurrentProdBundle", bundleOptions);

    const bundleProdOptions = {};
    bundleOptions.forEach((bundles) => {
      const bundleID = bundles.uid;
      if (bundles.options.length == 1) {
        selectedBundles[bundleID] = bundles.options[0].product.sku;
        selectedBundleChildSku[bundleID] = null;
      }
      bundles.options.forEach((option) => {
        const prodOptions = [];
        if (option.product.configurable_options.length == 1) {
          const values = [];
          option.product.variants.forEach((element) => {
            const value = {
              label: element.attributes[0].label,
              value_index: element.product.sku,
              price: element.product.price_range.minimum_price.final_price.value.toFixed(
                2
              ),
            };
            values.push(value);
          });
          const prodOption = {
            index: 0,
            attribute_code:
              option.product.configurable_options[0].attribute_code,
            label: option.product.configurable_options[0].label,
            id: option.product.configurable_options[0].id,
            choice: null,
            values: values,
          };
          prodOptions.push(prodOption);
        } else {
          option.product.configurable_options.forEach((option, index) => {
            if (index == 0) {
              const prodOption = {
                index: index,
                attribute_code: option.attribute_code,
                label: option.label,
                id: option.id,
                choice: null,
                values: option.values,
              };
              prodOptions.push(prodOption);
            } else {
              const prodOption = {
                index: index,
                attribute_code: option.attribute_code,
                label: option.label,
                choice: null,
                id: option.id,
                values: [],
              };
              prodOptions.push(prodOption);
            }
          });
        }
        let sortArray = [];
        option.product.configurable_options[0].values.forEach((v) => {
          sortArray.push(v.label);
        })
        prodOptions.forEach((prodOption) => {
          if (prodOption.attribute_code == 'fkv_maten') {
            prodOption.values.sort((a, b) => sortArray.indexOf(a.label) - sortArray.indexOf(b.label))
          }
        })
        bundleProdOptions[bundleID] = prodOptions;
      });
    });
    commit("setCurrentProdBundleOptions", bundleProdOptions);
    commit("setCurrentProdSelectedBundles", selectedBundles);
    commit("setCurrentProdSelectedBundleChildSku", selectedBundleChildSku);
  },
  async addNotifyEmail(_, { email, sku }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];
    let headers = {};
    if (store.getters["user/getIsLoggedIn"] != false) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters["user/getUserToken"],
        Store: storeview,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + config.shop.consumerKey,
        Store: storeview,
      };
    }

    let query =
      'mutation { productAlertSubscription( input: { email: "' + email + '" ';
    query = query + 'sku:"' + sku + '"';
    query = query + "type:stock}";

    query = query + " ) { email sku type } }";

    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("addNotifyEmail", "product store", e)();
      throw e;
    });

    if (retval.data.data.productAlertSubscription != null) {
      return true;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          const msg = {
            type: "danger",
            title: i18n.t("Add to cart"),
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  },
  async loadCrossSellProducts({ commit }, { sku, hello_retail_id }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    let query = '{ purchasedTogether(sku:"' + sku + '",pageType: "product",pageSize: 10, currentPage: 1) {';
    query = query + graphql.queryFields.productOverview.replace('__typename', '__typename aw_source(pageType: "product" trackingUserId:"' + hello_retail_id + '" requestType: "purchasedTogether" sku:"' + sku + '")') + "} }";

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadCrossSellProducts", "home", e)();
      throw e;
    });
    if (retval && retval.data && retval.data.data && retval.data.data.purchasedTogether) {
      const products = retval.data.data.purchasedTogether;
      if ((products.items && products.items.length > 0)) {
        products.items.forEach(item => {
          const aw_source = item.aw_source;
          const sku = item.sku;
          commit("setAwSource", { sku, aw_source });
        });
      }
      commit("setCrossSellProducts", products.items);
    } else {
      commit("setCrossSellProducts", []);
    }
  },
  async loadUpSellProducts({ commit }, { sku, hello_retail_id }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    let query = '{ alternatives(sku:"' + sku + '",pageType: "product",pageSize: 10, currentPage: 1) {';
    query = query + graphql.queryFields.productOverview.replace('__typename', '__typename aw_source(pageType: "product" trackingUserId:"' + hello_retail_id + '" requestType: "alternatives" sku:"' + sku + '")') + "} }";

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadUpSellProducts", "product", e)();
      throw e;
    });
    if (retval && retval.data && retval.data.data && retval.data.data.alternatives) {
      const products = retval.data.data.alternatives;
      if ((products.items && products.items.length > 0)) {
        products.items.forEach(item => {
          const aw_source = item.aw_source;
          const sku = item.sku;
          commit("setAwSource", { sku, aw_source });
        });
      }
      commit("setUpSellProducts", products.items);
    } else {
      commit("setUpSellProducts", []);
    }
  },
};

const mutations = {
  ...coreProduct.mutations,
  setCurrentCustomMeasures(state, data) {
    state.currentCustomMeasures = data;
  },
  setOptionValue(state, data) {
    for (let i = data.index + 1; i < state.currentOptions.length; i++) {
      if (state.currentOptions[i].attribute_code == "fkv_maten") {
        state.currentCustomMeasures = [];
      }
      state.currentOptions[i].choice = null;
      state.currentOptions[i].values = [];
    }
    if (data.index == state.currentOptions.length - 1) {
      state.currentChildSku = data.value;
    } else {
      state.currentOptions[data.index].choice = data.value;
      state.currentChildSku = null;
      let products = state.currentConfig.variants;
      for (let i = 0; i <= data.index; i++) {
        products = products.filter(function (item) {
          const retval = item.attributes.find((o) => {
            if (o.value_index == state.currentOptions[i].choice) {
              return true;
            }
          });
          if (retval != null) {
            return true;
          }
        });
      }
      if (data.index == state.currentOptions.length - 2) {
        const values = [];
        const cvalues = [];
        products.forEach((element) => {
          const retval = element.attributes.find((o) => {
            if (o.code == state.currentOptions[data.index + 1].attribute_code) {
              return true;
            }
          });
          const value = {
            label: retval.label,
            value_index: element.product.sku,
          };
          values.push(value);

          if (retval.code == "fkv_maten") {
            if (state.currentConfig.size_chart.sizes.length != null) {
              const found = state.currentConfig.size_chart.sizes.find((v) => {
                if (v.from.option_id == retval.value_index) {
                  return true;
                }
              });
              if (found != null) {
                const cvalue = {
                  label: found.to.label,
                  value_index: element.product.sku,
                };
                cvalues.push(cvalue);
              }
            }
          }
        });
        state.currentOptions[data.index + 1].values = values;
        state.currentCustomMeasures = cvalues;
      }
    }
  },
  /**
   * set bundle option value
   *
   * @param {array} data
   */
  setCurrentProdBundleOptionValue(state, data) {
    for (
      let i = data.index + 1;
      i < state.currentProdBundleOptions[data.bundle_id].length;
      i++
    ) {
      state.currentProdBundleOptions[data.bundle_id][i].choice = null;
      state.currentProdBundleOptions[data.bundle_id][i].values = [];
    }

    if (
      data.index ==
      state.currentProdBundleOptions[data.bundle_id].length - 1
    ) {
      state.currentProdSelectedBundleChildSku = {
        ...state.currentProdSelectedBundleChildSku,
        [data.bundle_id]: data.value,
      };
    } else {
      state.currentProdBundleOptions[data.bundle_id][data.index].choice =
        data.value;

      state.currentProdSelectedBundleChildSku = {
        ...state.currentProdSelectedBundleChildSku,
        [data.bundle_id]: null,
      };

      let prod = state.currentProdBundle.find((v) => {
        if (v.option_id == data.bundle_id) {
          return true;
        }
      });
      let products = prod.options[0].product.variants;
      for (let i = 0; i <= data.index; i++) {
        products = products.filter(function (item) {
          const retval = item.attributes.find((o) => {
            if (
              o.value_index ==
              state.currentProdBundleOptions[data.bundle_id][i].choice
            ) {
              return true;
            }
          });
          if (retval != null) {
            return true;
          }
        });
      }
      if (
        data.index ==
        state.currentProdBundleOptions[data.bundle_id].length - 2
      ) {
        const values = [];
        products.forEach((element) => {
          const retval = element.attributes.find((o) => {
            if (
              o.code ==
              state.currentProdBundleOptions[data.bundle_id][data.index + 1]
                .attribute_code
            ) {
              return true;
            }
          });
          const value = {
            label: retval.label,
            value_index: element.product.sku,
          };
          values.push(value);
        });
        state.currentProdBundleOptions[data.bundle_id][
          data.index + 1
        ].values = values;
      }
    }
  },
  setCurrentProdBundleOptionQty(state, data) {
    const bundle = state.currentProdBundle.find((v) => v.uid == data.bundle_id);
    if (!bundle) return;
    const bundleOption = bundle.options[data.index];
    if (bundleOption) bundleOption.quantity = data.value;
  },
  /**
   * set current bundle configuration
   *
   * @param {object} data
   * @private
   */
  setCurrentProdBundle(state, data) {
    state.currentProdBundle = data;
  },
  /**
   * set current bundle options
   *
   * @param {array} data
   * @private
   */
  setCurrentProdBundleOptions(state, data) {
    state.currentProdBundleOptions = data;
  },
  /**
   * set selected bundles
   *
   * @param {array} data
   * @private
   */
  setCurrentProdSelectedBundles(state, data) {
    state.currentProdSelectedBundles = data;
  },
  /**
   * set Selected Bundle child sku
   *
   * @param {array} data
   * @private
   */
  setCurrentProdSelectedBundleChildSku(state, data) {
    state.currentProdSelectedBundleChildSku = data;
  },
  setCrossSellProducts(state, data) {
    state.crossSellProducts = data;
  },
  setUpSellProducts(state, data) {
    state.upSellProducts = data;
  },
  setAwSource(state, payload) {
    let awSource = JSON.parse(localStorage.getItem("aw_source")) ?? [];
    awSource.push(payload);
    localStorage.setItem("aw_source", JSON.stringify(awSource));
  }
};

const getters = {
  ...coreProduct.getters,

  getCurrentCustomMeasures: (state) => state.currentCustomMeasures,
  getCurrentProdBundle: (state) => state.currentProdBundle,
  getCurrentProdBundleOptions: (state) => state.currentProdBundleOptions,
  getCurrentProdSelectedBundles: (state) => state.currentProdSelectedBundles,
  getCurrentProdSelectedChildSkus: (state) =>
    state.currentProdSelectedBundleChildSku,
  getCrossSellProducts: (state) => state.crossSellProducts,
  getUpSellProducts: (state) => state.upSellProducts,
  getAwSource: (state) => state.aw_source,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
