import { Logger } from "@storefront/core/lib/logger";
import { getProductByCategoryId } from "@storefront/core/data-resolver/products";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import graphql from "@graphql";
import axios from "axios";

const state = () => ({
  Bundles: [],
  mostPurchased: [],
  retargeted: [],
  aw_source: null,
});

const actions = {
  async loadBundles({ commit }) {
    const products = await getProductByCategoryId(config.bundleList, 1).catch(
      (e) => {
        Logger.error("loadBundles", "menu.ts", e)();
        throw e;
      }
    );
    commit("setBundleProducts", products.items);
  },
  async loadMostPurchasedProducts({ commit }, { hello_retail_id }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    let query = '{ mostPurchased(pageType: "home",pageSize: 10, currentPage: 1) {';
    query = query + graphql.queryFields.productOverview.replace('__typename', '__typename aw_source(pageType: "home" trackingUserId:"' + hello_retail_id + '" requestType: "mostPurchased")') + "} }";

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadMostPurchasedProducts", "home", e)();
      throw e;
    });
    if (retval && retval.data && retval.data.data && retval.data.data.mostPurchased) {
      const products = retval.data.data.mostPurchased;
      if ((products.items && products.items.length > 0)) {
        products.items.forEach(item => {
          const aw_source = item.aw_source;
          const sku = item.sku;
          commit("setAwSource", { sku, aw_source });
        });
      }
      commit("setMostPurchasedProducts", products.items);
    } else {
      commit("setMostPurchasedProducts", []);
    }
  },
  async loadRetargetedProducts({ commit }, { hello_retail_id, page }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];
    // if (hello_retail_id == null) {
    //   hello_retail_id = "65116bed4f43f24864a192c3";
    // }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    let query = '{ retargeted(pageType: "' + page + '",pageSize: 10, currentPage: 1,trackingUserId:"' + hello_retail_id + '") {';
    query = query + graphql.queryFields.productOverview.replace('__typename', '__typename aw_source(pageType: "' + page + '" trackingUserId:"' + hello_retail_id + '" requestType: "retargeted")') + "} }";

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadRetargetedProducts", "home", e)();
      throw e;
    });
    if (retval && retval.data && retval.data.data && retval.data.data.retargeted) {
      const products = retval.data.data.retargeted;
      if ((products.items && products.items.length > 0)) {
        products.items.forEach(item => {
          const aw_source = item.aw_source;
          const sku = item.sku;
          commit("setAwSource", { sku, aw_source });
        });
      }
      commit("setRetargetedProducts", products.items);
    } else {
      commit("setRetargetedProducts", []);
    }
  },
};

const mutations = {
  setBundleProducts(state, payload) {
    state.Bundles = payload;
  },
  setMostPurchasedProducts(state, payload) {
    state.mostPurchased = payload
  },
  setRetargetedProducts(state, payload) {
    state.retargeted = payload
  },
  setAwSource(state, payload) {
    let awSource = JSON.parse(localStorage.getItem("aw_source")) ?? [];
    awSource.push(payload);
    localStorage.setItem("aw_source", JSON.stringify(awSource));
  }
};

const getters = {
  getBundleProducts: (state) => state.Bundles,
  getRandomBundles: (state) => (nr) => {
    const bundles = JSON.parse(JSON.stringify(state.Bundles));
    const shuffled = bundles.sort(() => 0.5 - Math.random());
    if (nr > bundles.length) {
      nr = bundles.length;
    }
    return shuffled.slice(0, nr);
  },
  getMostPurchasedProducts: (state) => state.mostPurchased,
  getRetargetedProducts: (state) => state.retargeted,
  getAwSource: (state) => state.aw_source
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
