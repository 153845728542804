class QLStringify {
  stringify(obj) {
    let start = "";
    let end = "";
    if (typeof obj === "object") {
      if (Array.isArray(obj) == true) {
        start = "[ " + start;
        end = " ]" + end;
      } else {
        start = "{ " + start;
        end = " }" + end;
      }
    }
    return start + end;
  }
  /*
  strObj(obj) {
    let start = "{";
      let end = "}";
      
      obj.forEach((set, idx) => {
      })
  }
  */
}

const qls = new QLStringify();

export { qls as QLStringify };
