import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
//import store from "@/store";\

/**
 * Load the bloglist from magento graphql with pagination
 * @param {integer} currentPage
 * @param {integer} pageSize
 * @returns array of blog posts
 */
export async function loadBlogList(currentPage, pageSize) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ blogPosts(currentPage: " +
    currentPage +
    ", pageSize: " +
    pageSize +
    ', sort: ["DESC"]) { total_count total_pages items { identifier categories { title category_url_path } post_id title short_filtered_content featured_image featured_img_alt first_image creation_time author { identifier author_id name author_url } } } }';
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentBlocks", "data-resolver cms", e)();
    throw e;
  });
  return retval.data.data.blogPosts;
}
/**
 * Load a blog post based on the identifier
 * @param {string} identifier
 * @returns object of a blogpost
 */
export async function loadBlog(identifier) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ blogPost (id: " +
    JSON.stringify(identifier) +
    " ){ identifier categories { title category_url_path } post_id title short_filtered_content content filtered_content featured_image featured_img_alt first_image creation_time author { identifier author_id name author_url } post_url creation_time post_url meta_title meta_keywords meta_description} }";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentBlocks", "data-resolver cms", e)();
    throw e;
  });
  return retval.data.data.blogPost;
}
/**
 * Load the bloglist from magento graphql with pagination
 * @param {integer} currentPage
 * @param {integer} pageSize
 * @returns array of blog posts
 */
export async function loadBlogsPerCategory(category_id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    '{ blogPosts(filter: {category_id: {eq: "' +
    category_id +
    '"}}  ) { total_count total_pages items { identifier post_id title short_filtered_content featured_image featured_img_alt first_image creation_time author { identifier author_id name author_url } } } }';
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentBlocks", "data-resolver cms", e)();
    throw e;
  });
  return retval.data.data.blogPosts;
}
