//import { loadCategoryById } from "@storefront/core/data-resolver/categories";
import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import config from "@config";
import axios from "axios";
import graphql from "@graphql";

import {
  getProductFiltersByFilter,
  getProductByFilter,
  getProductByPartfinder,
  getProductFiltersByPartfinder,
  getCartCrossSell
} from "@storefront/core/data-resolver/products";

import store from "@/store";
import { isServer } from "@storefront/core/helpers";
const state = () => ({
  current: null,
  menuCategories: [],
  productFilters: [],
  activeFilter: [],
  priceFilter: [0, 0],
  filteredCount: 0,
  products: [],
  ShowProducts: [],
  sorting: "default",
  cartCrossSell: [],
  aw_source: null,
});

const actions = {
  async loadProducts({ commit }, { filter, totalPages }) {
    for (let page = 2; page < totalPages + 1; page++) {
      const retv = await getProductByFilter(filter, page);
      commit("addProducts", retv.items);
    }
  },
  async loadFilters({ commit }, { filter = "" }) {
    const filters = await getProductFiltersByFilter(filter).catch((e) => {
      throw e;
    });

    const uFilters = [];
    filters.aggregations.forEach((element) => {
      if (element.attribute_code != "category_id") {
        if (element.attribute_code == "price") {
          element.min = parseInt(element.min_value);

          element.max = Math.ceil(element.max_value);

          commit("setPriceFilter", [element.min, element.max]);
        }
        uFilters.push(element);
      }
    });
    commit("setProductFilters", uFilters);
  },

  async load({ commit, dispatch }, { filter = null }) {
    if (filter == null) {
      commit("setProductFilters", {});
      commit("resetActiveFilter");
      commit("setPriceFilter", [0, 0]);

      return false;
    } else {
      commit("setProducts", []);
      dispatch("loadFilters", { filter: filter });

      const retval = await getProductByFilter(filter, 1);
      commit("addProducts", retval.items);
      const totalPages = retval.page_info.total_pages;
      if (!isServer) {
        dispatch("loadProducts", { filter: filter, totalPages: totalPages });
      }
    }

    return true;
  },
  async loadPartfinder({ commit, dispatch }) {
    commit("setProducts", []);
    dispatch("loadFiltersPathfinder");

    const retval = await getProductByPartfinder(1);
    commit("addProducts", retval.items);
    const totalPages = retval.page_info.total_pages;
    if (!isServer) {
      dispatch("loadProductsPathfinder", {
        totalPages: totalPages,
      });
    }

    return true;
  },
  async loadProductsPathfinder({ commit }, { totalPages }) {
    for (let page = 2; page < totalPages + 1; page++) {
      const retv = await getProductByPartfinder(page);
      commit("addProducts", retv.items);
    }
  },
  async loadFiltersPathfinder({ commit }) {
    const filters = await getProductFiltersByPartfinder().catch((e) => {
      throw e;
    });
    const uFilters = [];
    filters.aggregations.forEach((element) => {
      if (element.attribute_code != "category_id") {
        if (element.attribute_code == "price") {
          element.min = parseInt(element.min_value);

          element.max = Math.ceil(element.max_value);

          commit("setPriceFilter", [element.min, element.max]);
        }
        uFilters.push(element);
      }
    });
    commit("setProductFilters", uFilters);
  },
  async loadPartfinderFilters({ commit }) {
    const filters = await getProductFiltersByPartfinder().catch((e) => {
      throw e;
    });
    const uFilters = [];
    filters.aggregations.forEach((element) => {
      if (element.attribute_code != "category_id") {
        if (element.attribute_code == "price") {
          element.min = parseInt(element.min_value);

          element.max = Math.ceil(element.max_value);

          commit("setPriceFilter", [element.min, element.max]);
        }
        uFilters.push(element);
      }
    });
    commit("setProductFilters", uFilters);
  },
  loadCrossSellProducts({ commit, dispatch }, { skus }) {
    var products = [];
    skus?.forEach((sku) => {
      dispatch("loadCrossSellProduct", { sku }).then(productData => {
        productData.forEach(product => { products.push(product); });
      });
    });

    commit("setCrossSellProducts", products);
  },
  async loadCrossSellForCart({ commit }, { skus }) {
    const products = await getCartCrossSell(skus).catch((e) => {
      Logger.error("loadCrossSellForCart", "product store actions load", e)();
      throw e;
    });
    if (products) {
      commit("setCrossSellProducts", products);
    }
  },
  async loadCrossSellProduct({ commit }, { sku, hello_retail_id }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    var query = "";
    query = '{ purchasedTogether(sku:"' + sku + '",pageType: "product",pageSize: 10, currentPage: 1) {';
    query = query + graphql.queryFields.productOverview.replace('__typename', '__typename aw_source(pageType: "product" trackingUserId:"' + hello_retail_id + '" requestType: "purchasedTogether" sku:"' + sku + '")') + "} }";

    const products = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadCrossSellProducts", "sale", e)();
      throw e;
    }).then((response) => {
      const products = response.data.data.purchasedTogether;
      if ((products && products.items && products.items.length > 0)) {
        products.items.forEach(item => {
          const aw_source = item.aw_source;
          const sku = item.sku;
          commit("setAwSource", { sku, aw_source });
        });
        return products.items;
      }
      return [];
    });
    return products;
  }
};

const mutations = {
  // always and only change vuex state through mutations.
  setCurrentCategory(state, data) {
    state.current = data;
    const breadcrumbs = data.breadcrumbs;
    let currentName = "undefined";
    if (typeof data.name == "string") {
      currentName = data.name;
    }
    const bcrumb = { current: currentName, routes: [] };
    if (breadcrumbs != null) {
      breadcrumbs.sort((a, b) => {
        if (a.category_level < b.category_level) {
          return -1;
        }
        if (a.category_level > b.category_level) {
          return 1;
        }
        return 0;
      });
      let path = "";
      breadcrumbs.forEach((element) => {
        if (path.length > 0) {
          path = path + "/";
        }
        path = path + element.category_url_key;
        let name = "undefined";
        if (typeof element.category_name == "string") {
          name = element.category_name;
        }
        const bc = {
          name: name,
          route_link: path,
        };
        bcrumb.routes.push(bc);
      });
    }
    store.commit("breadcrumbs/set", bcrumb);
  },
  setProductFilters(state, data) {
    state.productFilters = data;
  },
  setActiveFilter(state, data) {
    state.activeFilter.push(data);
    store.commit("sale/doFilterProducts");
  },
  resetActiveFilter(state) {
    state.activeFilter = [];
    store.commit("sale/doFilterProducts");
  },
  setProducts(state, data) {
    state.products = data;
    state.ShowProducts = data;
  },
  addProducts(state, data) {
    data.forEach((element) => {
      const retval = state.products.find((o) => {
        if (o.sku == element.sku) {
          return true;
        }
      });
      if (retval == null) {
        state.products.push(element);
      }
    });
    store.commit("sale/doFilterProducts");
  },
  setPriceFilter(state, data) {
    state.priceFilter = data;
    store.commit("sale/doFilterProducts");
  },
  setSorting(state, data) {
    state.sorting = data;
    store.commit("sale/doSorting");
  },
  addActiveFilter(state, data) {
    const obj = state.activeFilter.find((o) => {
      if (o.value == data.value) {
        return true; // stop saleing
      }
    });
    if (obj == null) {
      state.activeFilter.push(data);
    }
    store.commit("sale/doFilterProducts");
  },
  removeActiveFilter(state, data) {
    if (state.activeFilter.length > 0) {
      state.activeFilter.find((o, i) => {
        if (o.value == data.value) {
          state.activeFilter.splice(i, 1);
          return true;
        }
      });
      store.commit("sale/doFilterProducts");
    }
  },
  doSorting(state) {
    if (state.sorting == "default") {
      store.commit("sale/doFilterProducts");
    } else {
      state.ShowProducts.sort((a, b) => {
        if (state.sorting == "name_up") {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
        } else if (state.sorting == "name_down") {
          if (a.name > b.name) {
            return -1;
          }
          if (a.name < b.name) {
            return 1;
          }
        } else if (state.sorting == "price_up") {
          if (
            a.price_range.minimum_price.final_price.value <
            b.price_range.minimum_price.final_price.value
          ) {
            return -1;
          }
          if (
            a.price_range.minimum_price.final_price.value >
            b.price_range.minimum_price.final_price.value
          ) {
            return 1;
          }
        } else if (state.sorting == "price_down") {
          if (
            a.price_range.minimum_price.final_price.value >
            b.price_range.minimum_price.final_price.value
          ) {
            return -1;
          }
          if (
            a.price_range.minimum_price.final_price.value <
            b.price_range.minimum_price.final_price.value
          ) {
            return 1;
          }
        } else if (state.sorting == "newest") {
          if (a.created_at < b.created_at) {
            return -1;
          }
          if (a.created_at > b.created_at) {
            return 1;
          }
        } else if (state.sorting == "bestsold") {
          if (a.sales_data.orders < b.sales_data.orders) {
            return -1;
          }
          if (a.sales_data.orders > b.sales_data.orders) {
            return 1;
          }
        }
        return 0;
      });
    }
  },
  doFilterProducts(state) {
    const filters = [];
    state.activeFilter.forEach((el) => {
      if (filters.length > 0) {
        const obj = filters.find((o, i) => {
          if (o.attribute_code == el.attribute_code) {
            filters[i].values.push(el.label);
            return true;
          }
        });
        if (obj == null) {
          const values = [el.label];
          const filter = { attribute_code: el.attribute_code, values: values };
          filters.push(filter);
        }
      } else {
        const values = [el.label];
        const filter = { attribute_code: el.attribute_code, values: values };
        filters.push(filter);
      }
    });
    if (state.products.length > 0) {
      const products = state.products.filter(function (item) {
        //filter first on price range
        if (
          item.price_range.minimum_price.final_price.value >=
          state.priceFilter[0] &&
          item.price_range.minimum_price.final_price.value <=
          state.priceFilter[1]
        ) {
          let doShow = true;
          //check if there are any filters
          if (filters.length > 0) {
            doShow = false;
            const isFound = [];
            //move trhough each filter

            filters.forEach((element, index) => {

              isFound[index] = false;
              element.values.forEach((el) => {
                const retval = item.filter_attributes.find((o) => {
                  if (o.attribute_code == element.attribute_code) {
                    const found = o.values.find((v) => {
                      if (v.option_label == el) {
                        return true;
                      }
                    });
                    if (found != null) {
                      return true;
                    }
                  } else {
                    return false;
                  }
                });
                if (retval != null) {
                  isFound[index] = true;
                  return true;
                }
              });
            });
            if (
              isFound.find((o) => {
                return o == false;
              }) == null
            ) {
              doShow = true;
            }
          }
          return doShow;
        } else {
          return false;
        }
      });
      state.ShowProducts = products;
    }
  },
  setCrossSellProducts(state, data) {
    state.cartCrossSell = data;
  },
  setAwSource(state, payload) {
    let awSource = JSON.parse(localStorage.getItem("aw_source")) ?? [];
    awSource.push(payload);
    localStorage.setItem("aw_source", JSON.stringify(awSource));
  }
};

const getters = {
  getCurrentCategory: (state) => state.current,
  getProductFilters: (state) => state.productFilters,
  getActiveFilter: (state) => state.activeFilter,
  getShowProducts: (state) => state.ShowProducts,
  getPriceFilter: (state) => state.priceFilter,
  getSorting: (state) => state.sorting,
  getCrossSellProducts: (state) => state.cartCrossSell,
  getAwSource: (state) => state.aw_source
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
