import { loadFAQ } from "@storefront/core/data-resolver/faq";
import { Logger } from "@storefront/core/lib/logger";
const state = () => ({
  faq: null, // shown product
});

const actions = {
  /**
   * load FAQ data
   *
   */
  async load({ commit }) {
    const faq = await loadFAQ().catch((e) => {
      Logger.error("loadFAQ", "faq store actions", e)();
      throw e;
    });
    if (faq != null) {
      commit("setFaq", faq.items);
    }
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  /**
   * set the faq
   *
   * @param {array} data
   */
  setFaq(state, data) {
    state.faq = data;
  },
};

const getters = {
  getFaq: (state) => state.faq,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
